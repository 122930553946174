import { useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import useTimeout from "hooks/useTimeout";
import { ContentContainer } from "styles/generalStyles";

import Button from "components/atoms/Button";
import BackArrow from "components/atoms/BackArrow";

const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  justify-content: space-between;
  padding: 1rem 0;
`;

const Header = styled.h3`
  text-align: center;
  margin-top: 1rem;
  position: relative;

  > span {
    display: inline-block;
    transform: translateY(0.5rem);
  }
`;

const Main = styled.main``;

const ApiInfo = styled.div`
  margin-top: 3rem;

  div {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

const Actions = styled.div`
  .btn {
    text-transform: lowercase;

    &:not(last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .document {
    background-color: dodgerblue;
  }

  .window {
    background-color: limegreen;
  }

  .history {
    background-color: coral;
  }

  .href-pathname {
    background-color: crimson;
  }
`;

function ReloadTest() {
  const [withTimeOut, setWithTimeOut] = useState(false);
  const [loading, setLoading] = useState({
    window: false,
    document: false,
    history: false,
    hrefPathname: false,
    mathRandom: false,
  });
  const setTimeOut = useTimeout();
  const navigate = useNavigate();

  const onDocumentBtnClick = () => {
    if (withTimeOut) {
      setLoading((prev) => ({ ...prev, document: true }));

      setTimeOut(() => {
        document.location.reload();
      }, 1000);
    } else {
      document.location.reload();
    }
  };

  const onWindowBtnClick = () => {
    if (withTimeOut) {
      setLoading((prev) => ({ ...prev, window: true }));

      setTimeOut(() => {
        window.location.reload();
      }, 1000);
    } else {
      window.location.reload();
    }
  };

  const onHistoryGoBtnClick = () => {
    if (withTimeOut) {
      setLoading((prev) => ({ ...prev, history: true }));

      setTimeOut(() => {
        window.history.go(0);
      }, 1000);
    } else {
      window.history.go(0);
    }
  };

  const onHrefPathnameBtnClick = () => {
    if (withTimeOut) {
      setLoading((prev) => ({ ...prev, hrefPathname: true }));

      setTimeOut(() => {
        window.location.href = window.location.pathname;
      }, 1000);
    } else {
      window.location.href = window.location.pathname;
    }
  };

  const reloadPage = () => {
    const { origin, hash } = window.location;
    const path = `${origin}/?reload=${(Math.random() * 100000).toFixed(0)}/${hash}`;
    window.location.href = path;
  };

  const onMathRandomBtnClick = () => {
    // const { origin, hash } = window.location;
    // const path = `${origin}/?reload=${Math.random()}/${hash}`;

    if (withTimeOut) {
      setLoading((prev) => ({ ...prev, mathRandom: true }));

      setTimeOut(() => {
        reloadPage();
      }, 1000);
    } else {
      reloadPage();
    }
  };

  return (
    <ContentContainer>
      <Container>
        <Main>
          <Header>
            <BackArrow
              onClick={() => {
                navigate("/profile");
              }}
            />
            <span>Reload Test</span>
          </Header>

          {/* <ApiInfo>
            <div>
              &#8226;&nbsp;window.location.reload:&nbsp;
              {"reload" in window.location ? "TRUE" : "FALSE"}
            </div>
            <div>
              &#8226;&nbsp;document.location.reload:&nbsp;
              {"reload" in document.location ? "TRUE" : "FALSE"}
            </div>
            <div>
              &#8226;&nbsp;window.history:&nbsp;
              {"history" in window ? "TRUE" : "FALSE"}
            </div>
            <div>
              &#8226;&nbsp;window.location.href:&nbsp;
              {"href" in window.location ? "TRUE" : "FALSE"}
            </div>
            <div>
              &#8226;&nbsp;window.location.pathname:&nbsp;
              {"pathname" in window.location ? "TRUE" : "FALSE"}
            </div>
          </ApiInfo> */}
        </Main>
        <Actions>
          <div
            style={{
              marginBottom: "1.5rem",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <label htmlFor='timeout'>
              With <b>time-out</b> (1s):
            </label>
            <input
              type='checkbox'
              id='timeout'
              onChange={() => {
                setWithTimeOut((prev) => !prev);
              }}
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            />
          </div>

          <Button
            label='window.location.reload()'
            customClass='btn window'
            onClick={onWindowBtnClick}
            loading={loading.window}
          />
          <Button
            label='document.location.reload()'
            customClass='btn document'
            onClick={onDocumentBtnClick}
            loading={loading.document}
          />

          <Button
            label='window.history.go(0)'
            customClass='btn history'
            onClick={onHistoryGoBtnClick}
            loading={loading.history}
          />

          <Button
            label='location.href = location.pathname'
            customClass='btn href-pathname'
            onClick={onHrefPathnameBtnClick}
            loading={loading.hrefPathname}
          />

          <Button
            label='?reload={Math.random()}'
            customClass='btn math-random'
            onClick={onMathRandomBtnClick}
            loading={loading.mathRandom}
          />
        </Actions>
      </Container>
    </ContentContainer>
  );
}

export default ReloadTest;
