import { useState, useRef, useEffect, ChangeEvent, FormEvent, useContext } from "react";
import styled, { css } from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import Select, { ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getCookie } from "react-use-cookie";
import { AxiosError } from "axios";

import { createTask } from "api/tasks";
import useModal from "hooks/useModal";
import useTimeout from "hooks/useTimeout";
import useCollection from "hooks/useCollection";
import useUsersForSelect from "hooks/useUsersForSelect";
import useNotification from "hooks/useNotification";
import { useAppSelector } from "redux/hooks/hooks";
import { Answers } from "types/types";
import { EditModalTypes } from "types/tasks.types";
import { UserOption, SelectedOption } from "types/assign-to-user.types";
import { Create, CreateTaskPayload, Related, TaskStatuses } from "types/tasks.types";
import { ReturnPathKeys } from "types/tasks.types";
import { getTranslations as getTasksTranslations } from "pages/user-tasks/translations/tasks.translations";
import { getTranslations as getSelectTranslations } from "translations/select.translations";
import { getTranslations as taskCreatorTranslations } from "./translations/task-creator.translations";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import { isMobile, ACCESS_TOKEN } from "utils/utils";

import Button from "components/atoms/Button";
import BackArrow from "components/atoms/BackArrow";
import ShimmerLoader from "components/atoms/loaders/ShimmerLoader";
import ConfirmationModal from "components/organisms/ConfirmationModal";

import {
  ContentContainer,
  Form,
  FormField,
  StyledLabel,
  Card,
  ModalStyles,
} from "styles/generalStyles";
import { selectControlStyles } from "styles/select.styles";
import TaskComment from "pages/selected-task-created/components/task-comment/TaskComment";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";
import { TaskTitle, TaskTitleContainer } from "styles/selected-task.styles";
import Input from "components/atoms/Input";

const Header = styled.div<{ isMobile: boolean }>`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.2rem;
  font-family: AuraAspect;
  text-align: center;
  position: relative;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }

  .header-title {
    display: inline-block;
    max-width: 82%;
    font-size: 2rem;

    @media screen and (min-width: 360px) {
      font-size: 2.2rem;
    }

    @media screen and (min-width: 380px) {
      font-size: 2.4rem;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .move-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Placeholder = styled.span`
  opacity: 0.5;
  font-style: italic;
  font-size: 1.6rem;
  margin-top: 0.2rem;
`;

const DeadlineInputWrapper = styled.div<{ isValue: boolean }>`
  .deadline-input {
    ${({ isValue }) =>
      !isValue &&
      css`
        color: hsl(0, 0%, 50%);
      `}
  }
`;

type Response<T> = T | null;

const { TITLE, COMMENT } = EditModalTypes;
const { Actions, ButtonContainer } = ModalStyles;
const { TO_DO } = TaskStatuses;
const { TASK_CREATOR } = ReturnPathKeys;
const { NO } = Answers;

function TaskCreator() {
  const { userOptions, isLoading } = useUsersForSelect();
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [deadline, setDeadline] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<SelectedOption | null>(null);
  const navigate = useNavigate();
  const {
    open: confirmationModalOpen,
    openModal: openConfirmationMopdal,
    closeModal: closeConfirmationModal,
    secondOpen: editModalOpen,
    openSecondModal: openEditModal,
    closeSecondModal: closeReactionModal,
  } = useModal();
  const { collection, entityId } = useCollection();
  const notify = useNotification();
  const setTimeOut = useTimeout();
  const { assessmentResponse } = useAppSelector(({ assesment }) => assesment);
  const {
    state: { answers, selectedQuestionnaire },
  } = useContext(QuestionnairesContext);

  const token = getCookie(ACCESS_TOKEN);

  const {
    i18n: { language },
  } = useTranslation();

  const { labels, modal, alert, titles, placeholders } =
    taskCreatorTranslations(language);
  const { label, placeholder, selectNoOptionsMessage } = getSelectTranslations(language);
  const { labels: tasksLabels } = getTasksTranslations(language);
  const isDirty = !!title || !!comment || !!selectedUser;
  const disabled = !title;

  const currentEditModalRef = useRef<EditModalTypes>();
  const initialTitle = useRef<string>("");
  // const initialComment = useRef<string>("");

  const modalType = currentEditModalRef.current;

  let editValue = "";

  if (modalType === TITLE) {
    editValue = title;
  }

  if (modalType === COMMENT) {
    editValue = comment;
  }

  let modalHeader = modalType ? modal.header[modalType] : "";

  const closeTaskCreator = () => {
    const path = sessionStorage.getItem(TASK_CREATOR) ?? "";

    navigate(path, { replace: true });

    sessionStorage.removeItem(TASK_CREATOR);
  };

  // --------------- Reset state handlers ---------------

  const resetTextValues = () => {
    if (modalType === TITLE) {
      setTitle(initialTitle.current);
    }
  };

  // --------------- On success handler ---------------

  const onSuccess = () => {
    notify(alert.success, "success");
    setTimeOut(() => {
      closeTaskCreator();
    }, 1000);
  };

  // --------------- On error handler ---------------

  const onError = () => {
    notify(alert.error, "error");
  };

  // --------------- API handler ---------------

  const { mutate, isLoading: createTaksLoading } = useMutation<
    Response<null>,
    AxiosError,
    CreateTaskPayload
  >(createTask, {
    onSuccess,
    onError,
  });

  // --------------- Submit handler ---------------

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const Title = title;
    const Description = comment;
    const AssignTo = selectedUser ? selectedUser.id : null;
    const status = TO_DO;
    const AnswersLinkWithTask = assessmentResponse.answers;
    const Deadline = deadline;

    let create: Create = [];

    if (collection) {
      create = [{ Tasks_id: "+", collection, item: { id: entityId } }];
    }

    const Related: Related = {
      create,
    };

    const payload: CreateTaskPayload = {
      AnswersLinkWithTask,
      Title,
      Description,
      AssignTo,
      Related,
      Deadline,
      status,
      token,
    };

    mutate(payload);
  };

  // --------------- On click handlers ---------------

  const onBackArrowClick = () => {
    if (!isDirty) {
      closeTaskCreator();
    } else {
      openConfirmationMopdal();
    }
  };

  const onConfirmBtnClick = () => {
    closeTaskCreator();
  };

  const onTextFieldClick = (modalType: EditModalTypes) => () => {
    currentEditModalRef.current = modalType;

    openEditModal();
  };

  const onCloseReactionModalIconClick = () => {
    resetTextValues();
    closeReactionModal();
  };

  const onSaveButtonClick = () => {
    initialTitle.current = title;
    // initialComment.current = comment;

    closeReactionModal();
  };

  // --------------- On change handlers ---------------

  const onAssignUserChange = (
    option: UserOption | null,
    { action }: ActionMeta<UserOption>,
  ) => {
    if (option) {
      const { id, value } = option;
      setSelectedUser({ id, value });
    }

    if (action === "clear") {
      setSelectedUser(null);
    }
  };

  const modifyTextValues = (value: string) => {
    const { current: modalType } = currentEditModalRef;

    if (modalType === TITLE) {
      setTitle(value);
    }

    if (modalType === COMMENT) {
      setComment(value);
    }
  };

  const onEdititTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    modifyTextValues(e.target.value);
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    modifyTextValues(textTranscript);
  };

  const onDeadlineInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const dealineValue = inputValue ? inputValue : null; // Deadline value cannot be an empty string; it has to be null instead.

    setDeadline(dealineValue);
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!selectedQuestionnaire) {
      return;
    }

    const { QuestionnaireTitle: Title } = selectedQuestionnaire;
    const negativeAnswers = answers.filter((answer) => answer.Value === NO);
    let negativeAnswersList = "";

    if (negativeAnswers.length) {
      negativeAnswersList = negativeAnswers
        .map(
          (answer) =>
            `[${labels.question}: ${answer.sort}] ${answer.OriginalText}${
              answer.ReactionValue ? `\n\n> ${answer.ReactionValue}` : ""
            }`,
        )
        .join("\n\n");
    }

    const taskTitle =
      Title +
      `${!!negativeAnswersList ? "\n\nNegatywne:\n\n" : ""}` +
      negativeAnswersList;

    initialTitle.current = taskTitle;

    setTitle(taskTitle);
  }, [selectedQuestionnaire, answers, labels.question]);

  return (
    <ContentContainer>
      <Header isMobile={isMobile}>
        <BackArrow onClick={onBackArrowClick} customClass='move-arrow' />
        <span className='header-title'>{labels.header}</span>
      </Header>

      <Card paddingTop>
        <Form onSubmit={onSubmit}>
          <FormField noMargin>
            <StyledLabel required>{labels.title}:</StyledLabel>

            <TaskTitleContainer
              onClick={onTextFieldClick(TITLE)}
              isMobile={isMobile}
              title={!isMobile ? titles.editTitle : ""}
            >
              {!!title ? (
                <TaskTitle defaultValue={title} value={title} />
              ) : (
                <Placeholder>{placeholders.title}...</Placeholder>
              )}
            </TaskTitleContainer>
          </FormField>

          <FormField>
            <StyledLabel>{label.assign}:</StyledLabel>

            {userOptions && (
              <Select
                defaultValue={null}
                onChange={onAssignUserChange}
                options={userOptions}
                isSearchable
                isClearable
                placeholder={placeholder.user}
                noOptionsMessage={() => <span>{selectNoOptionsMessage}</span>}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    ...selectControlStyles(false),
                  }),
                }}
              />
            )}

            {isLoading && <ShimmerLoader />}
          </FormField>

          <FormField>
            <StyledLabel>{labels.comment}:</StyledLabel>

            <TaskComment comment={comment} onTextFieldClick={onTextFieldClick} create />
          </FormField>

          <FormField>
            <StyledLabel>{tasksLabels.deadline}:</StyledLabel>

            <DeadlineInputWrapper isValue={!!deadline}>
              <Input
                type='datetime-local'
                onChange={onDeadlineInputChange}
                value={deadline ?? undefined}
                customClass='deadline-input'
              />
            </DeadlineInputWrapper>
          </FormField>

          <Actions>
            <ButtonContainer middle>
              <Button
                label={labels.button.create}
                disabled={disabled || createTaksLoading}
                loading={createTaksLoading}
                type='submit'
              />
            </ButtonContainer>
          </Actions>
        </Form>
      </Card>

      <ReactionModal
        header={modalHeader}
        open={editModalOpen}
        onClose={onCloseReactionModalIconClick}
        reactionTextAreaValue={editValue}
        onReactionTextAreaChange={onEdititTextareaChange}
        onTextTranscriptChange={onTextTranscriptChange}
        onSaveReactionBtnClick={onSaveButtonClick}
      />

      <ConfirmationModal
        open={confirmationModalOpen}
        message={modal.message}
        onClose={closeConfirmationModal}
        onClick={onConfirmBtnClick}
        buttonLabel={modal.button}
      />
    </ContentContainer>
  );
}

export default TaskCreator;
