import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";

import { ContentContainer, Header } from "styles/generalStyles";

import Button from "components/atoms/Button";
import BackArrow from "components/atoms/BackArrow";

const Title = styled.div``;

const CamerasList = styled.ul`
  margin-top: 1.5rem;
`;

const CameraItem = styled.li<{ border?: boolean }>`
  padding: 2rem;
  background-color: ${({ theme }) => theme.glacier};
  color: ${({ theme }) => theme.aliceBlue};
  border-radius: 0.4rem;
  transition: opacity 0.2s ease-out;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:hover {
    opacity: 0.9;
  }

  ${({ border }) =>
    border &&
    css`
      border: 6px solid ${({ theme }) => theme.successColor};
    `}
`;

const ButtonContainer = styled.div`
  margin-top: 4.5rem;
`;

const CaptureBtn = styled.label`
  background-color: ${({ theme }) => theme.primary};
  padding: 1rem;
  width: 15rem;
  border-radius: 0.4rem;
  color: #fff;
  text-align: center;
  cursor: pointer;

  &.video {
    background-color: ${({ theme }) => theme.secondary};
  }

  &.attachment {
    background-color: ${({ theme }) => theme.ceruleanBlue};
  }
`;

const PhotoContainer = styled.div``;

const VideoContainer = styled.div``;

function CameraTest() {
  const [src, setSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [attachmentSrc, setAttachmentSrc] = useState("");
  const navigate = useNavigate();
  let photoObjectURL = "";
  let videoObjectURL = "";
  let attachmentObjectURL = "";

  useEffect(() => {
    if (src) {
      URL.revokeObjectURL(photoObjectURL);
    }
  }, [src, photoObjectURL]);

  useEffect(() => {
    if (videoSrc) {
      URL.revokeObjectURL(videoObjectURL);
    }
  }, [videoSrc, videoObjectURL]);

  useEffect(() => {
    if (attachmentSrc) {
      URL.revokeObjectURL(attachmentObjectURL);
    }
  }, [attachmentSrc, attachmentObjectURL]);

  return (
    <ContentContainer>
      <Header standardFont>
        <BackArrow
          onClick={() => {
            navigate(-1);
          }}
        />
        <span>📷 Test </span>
      </Header>

      <CaptureBtn htmlFor='photo'>Take Photo</CaptureBtn>
      <input
        type='file'
        id='photo'
        capture='environment'
        accept='image/*'
        onChange={(e) => {
          if (e.target.files) {
            const photo = e.target.files[0];
            photoObjectURL = URL.createObjectURL(photo);

            setSrc(photoObjectURL);
          }
        }}
        style={{ visibility: "hidden" }}
      />

      <CaptureBtn htmlFor='video' className='video'>
        Record Video
      </CaptureBtn>
      <input
        type='file'
        id='video'
        capture='environment'
        accept='video/*'
        onChange={(e) => {
          if (e.target.files) {
            const video = e.target.files[0];
            videoObjectURL = URL.createObjectURL(video);

            setVideoSrc(videoObjectURL);
          }
        }}
        style={{ visibility: "hidden" }}
      />

      {/* <CaptureBtn htmlFor='attachment' className='attachment'>
        Attach File
      </CaptureBtn>

      <input
        type='file'
        id='attachment'
        accept='video/*, image/*, .png, .pdf'
        onChange={(e) => {
          if (e.target.files) {
            const attachment = e.target.files[0];
            console.log("🚩 ~~ attachment:", attachment);
            attachmentObjectURL = URL.createObjectURL(attachment);
            console.log("🚀 ~~ attachmentObjectURL:", attachmentObjectURL);

            setAttachmentSrc(attachmentObjectURL);
          }
        }}
        style={{ visibility: "hidden" }}
      /> */}

      <PhotoContainer>
        <img src={src} alt='' />
      </PhotoContainer>

      {/* <embed src={attachmentSrc} width='250' height='200' /> */}

      <video src={videoSrc} controls={!!videoSrc}></video>

      <ButtonContainer>
        <Button
          label='Odśwież stronę'
          question
          noShaddow
          onClick={() => {
            window.location.reload();
          }}
        />
      </ButtonContainer>
    </ContentContainer>
  );
}

export default CameraTest;
